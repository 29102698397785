
import {useState, useEffect} from 'react';
import swal from 'sweetalert';

const Location = () =>{
    let[city, pickCity] = useState( "" );
    let[msg, updateMsg] = useState("");
    let[alllocation, updateAllLocation] = useState([]);
    let[btndisable, updateBtnDisable] = useState(false);
    
    const getLocation = async() => {
        let url = "https://firstenquiry.com/liveapi/holiday/alllocation";

        try{
        await fetch(url)
        .then(response=>response.json())
        .then(location=>{
            updateAllLocation(location);
        })
        }catch(error){
            updateMsg("Network Error.. Please Try After Sometime");
        }
        
    }

    useEffect(()=>{
        getLocation();
    }, []);


    const save = (obj) => {

        obj.preventDefault();

        if (city === undefined || city.trim() === "") {

            swal("Invalid Input", "Please Enter The Correct Location", "warning");
            updateBtnDisable(false);

        } else {

            updateMsg("Please Wait...");

            updateBtnDisable(true);

            let url = "https://firstenquiry.com/liveapi/holiday/savelocation"; // post method
            let postData = {
                headers: { 'Content-Type': 'application/json' },
                method: 'post',
                body: JSON.stringify({"cityname" : city})
            }
            fetch(url, postData)
                .then(response => response.text())
                .then(info => {
                    swal( " Updated ", " Location Added Successfully ", "success" );
                    updateMsg("");
                    updateBtnDisable(false);
                    obj.target.reset();
                    getLocation(); // to reload the list
                })
        }
    }

    return(

        <div className='container mt-5'>

            <div className='row p-4'>
                <div className='col-lg-3'></div>
                <div className='col-lg-6'>
                    <h3 className='text-center'> <i className='fa fa-location-dot'></i></h3>
                    <h3 className='text-center'> Manage Tour Location </h3>
                    <p className='text-danger text-center'> {msg} </p>
                    <form onSubmit={save}>
                        <div className='text-center'>
                            <b>Enter Location Name</b>
                            <input type="text" className='m-3 form-control'
                                onChange={obj => pickCity(obj.target.value)} />
                            <button className='btn btn-success m-1' disabled={btndisable}> Save </button>
                        </div>
                    </form>
                </div>
                <div className='col-lg-3'></div>
            </div>

            <div className='row p-5'>
                <div className='col-lg-3'></div>

                <div className='col-lg-6'>

                    <h3 className='text-center'> Available Cities : {alllocation.length} </h3>

                    <table className='table table-bordered border-info mt-4'>

                        <thead>
                            <tr>
                                <th> City Id </th>
                                <th> City Name</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                alllocation.map((location, index) => {
                                    return (
                                        <tr key={index}>
                                            <td> {location.id} </td>
                                            <td> {location.cityname} </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>



                </div>

                <div className='col-lg-3'></div>
            </div>


        </div>
    )
}

export default Location;