import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";

const EditProfile = () => {
    
    let[msg, updateMsg] = useState("");
    let[btndisable, updateBtnDisable] = useState(false);

    let[myname, pickName] = useState("");
    let[myemail, pickEmail] = useState("");
    let[mypassword, pickPassword] = useState("");
    let[mymobile, pickMobile] = useState("");
    let[myaddress, pickAddress] = useState("");
    let[mycity, pickCity] = useState("");
    let[mypincode, pickPincode] = useState("");
    let[mywebsite, pickWebsite] = useState("");
    let[mygst, pickGst] = useState("");
    let[mypan, pickPan] = useState("");
    let[mytan, pickTan] = useState("");

    //state variables for form validation

    let[nameerror, updateNameError] = useState("");
    let[emailerror, updateEmailError] = useState("");
    let[mobileerror, updateMobileError] = useState("");
    let[passworderror, updatePasswordError] = useState("");
    let[addresserror, updateAddressError] = useState("");

    let agentid = localStorage.getItem("mytoken");

    
    const getAgent = async() => {

        let url = "https://firstenquiry.com/liveapi/Holiday/agentdetails";

        let postData = {
            headers : {'Content-Type' : 'application/json'},
            method : 'post',
            body : JSON.stringify({"agentid" : agentid})
        }

        try{
            await fetch(url, postData)
            .then(response=>response.json())
            .then(info=>{

                pickName(info.fullname);
                pickEmail(info.email);
                pickPassword(info.password);
                pickMobile(info.mobile);
                pickAddress(info.address);
                pickCity(info.city);
                pickPincode(info.pincode);
                pickWebsite(info.website);
                pickGst(info.gst);
                pickPan(info.pan);
                pickTan(info.tan);
    
            })
            }catch(error){
                updateMsg("Network error...Please Try Later");
            }
        

    }


    useEffect(()=>{
        getAgent();
    },[])


    const updateAgent = async(obj) => {
        obj.preventDefault();

        let formStatus = true;

        // for name validation
        if(myname === undefined || myname.trim() === "") {
            formStatus = false;
            updateNameError("Invalid Name");
        } else {
            updateNameError("");
        }

        // for email validation
        let epattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        if(!epattern.test(myemail)){
            formStatus = false;
            updateEmailError(" Invalid Email Address ");
        }else{
            updateEmailError("");
        }

        // for password validation
        let ppattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

        if(!ppattern.test(mypassword)){
            formStatus = false;
            updatePasswordError("Minimum Eight Characters, atleast one letter, one number, one special character");
        }else{
            updatePasswordError("");
        }

        // for mobile validation
        let mpattern = /^[0]?[6789]\d{9}$/;

        if(!mpattern.test(mymobile)){
            formStatus = false;
            updateMobileError("Invalid Mobile Number");
        }else{
            updateMobileError("");
        }

        // for address validation
        if(myaddress===undefined || myaddress.trim()===""){
            formStatus = false;
            updateAddressError("Invalid Address");
        }else{
            updateAddressError("");
        }

        if(formStatus==true){

            updateMsg("Please Wait...");

            updateBtnDisable(true);

            let url = "https://firstenquiry.com/liveapi/Holiday/updateagent";

            let newagentdata = { name:myname, email:myemail, password:mypassword, mobile:mymobile, address:myaddress, city:mycity, pincode:mypincode, website:mywebsite, gst:mygst, pan:mypan, tan:mytan, agentid:agentid }

            let postData = {
                headers : {'Content-Type' : 'application/json'},
                method : 'post',
                body : JSON.stringify(newagentdata)
            }

            try{
            await fetch(url, postData)
            .then(response=>response.text())
            .then(info=>{
                swal("Updated", "Agent Updated Successfully", "success");
                updateMsg("");
                updateBtnDisable(false);
                window.location.href="/profile";
            })
            }catch(error){
                updateMsg("Network error...Please Try Later");
                updateBtnDisable(false);
            }
        }

        
    }

    

    return(

        <div className="container mt-5">

            <form onSubmit={updateAgent} className="rounded shadow p-5 bg-light bg-gradient">

                <div className="row mb-4">

                    <h3 className="text-center mb-1"> Edit Profile Details Below </h3>

                    <i className="text-center text-danger mb-4"> {msg} </i>

                    <div className="col-xl-4">
                        <label> Business Name <i className="text-danger">*</i> </label>
                        <input type="text" className="form-control" value={myname} onChange={obj=>pickName(obj.target.value)}  />
                        <i className="text-danger"> {nameerror} </i>
                    </div>

                    <div className="col-xl-4">
                        <label> Email <i className="text-danger">*</i> </label>
                        <input type="email" className="form-control" value={myemail} onChange={obj=>pickEmail(obj.target.value)}  />
                        <i className="text-danger"> {emailerror} </i>
                    </div>

                    <div className="col-xl-4">
                        <label> Password <i className="text-danger">*</i> </label>
                        <input type="password" className="form-control" value={mypassword} onChange={obj=>pickPassword(obj.target.value)}  />
                        <i className="text-danger"> {passworderror} </i>
                    </div>

                </div>

                
                <div className="row mb-4">

                    <div className="col-xl-4">
                        <label> Mobile Number <i className="text-danger">*</i> </label>
                        <input type="number" className="form-control" value={mymobile} onChange={obj=>pickMobile(obj.target.value)}  />
                        <i className="text-danger"> {mobileerror} </i>
                    </div>

                    <div className="col-xl-8">
                        <label> Address <i className="text-danger">*</i> </label>
                        <textarea className="form-control" value={myaddress} onChange={obj=>pickAddress(obj.target.value)}/>
                        <i className="text-danger"> {addresserror} </i>
                    </div>

                </div>


                <div className="row mb-4">

                    <div className="col-xl-4">
                        <label> Website </label>
                        <input type="text" className="form-control" value={mywebsite} onChange={obj=>pickWebsite(obj.target.value)}  />
                    </div>

                    <div className="col-xl-4">
                        <label> City </label>
                        <input type="text" className="form-control" value={mycity} onChange={obj=>pickCity(obj.target.value)} />
                    </div>

                    <div className="col-xl-4">
                        <label> Pin Code </label>
                        <input type="number" className="form-control" value={mypincode} onChange={obj=>pickPincode(obj.target.value)} />
                    </div>

                </div>

                <div className="row mb-5">

                    <div className="col-xl-4">
                        <label> GST Number </label>
                        <input type="text" className="form-control" value={mygst} onChange={obj=>pickGst(obj.target.value)} />
                    </div>

                    <div className="col-xl-4">
                        <label> PAN Number </label>
                        <input type="text" className="form-control" value={mypan} onChange={obj=>pickPan(obj.target.value)}  />
                    </div>

                    <div className="col-xl-4">
                        <label> TAN Number </label>
                        <input type="text" className="form-control" value={mytan} onChange={obj=>pickTan(obj.target.value)}  />
                    </div>

                </div>

                <div className="row">

                    <div className="col-xl-12 text-center">
                        <button type="submit" className="btn btn-success me-3" disabled={btndisable}>
                        <i className="fa-solid fa-arrow-up"></i> Update </button>
                        
                        <Link className="btn btn-primary" to={`/profile`}>
                        <i className="fa-solid fa-backward"></i> Back To Profile </Link>
                    </div>

                </div>

            </form>

        </div>
    )
}

export default EditProfile;