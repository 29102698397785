import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import swal from "sweetalert";


const PackageDetails = () => {

    let{pid} = useParams();

    let[packdetails, updatePackDetails] = useState({});
    let[placedetails, updatePlaceDetails] = useState([]);
    let[dbtndisable, updateDBtnDisable] = useState(false);

    const getPackage = async() => {

        let url = "https://firstenquiry.com/liveapi/Holiday/packagedetails";

        let postData = {
            headers : {'Content-Type' : 'application/json'},
            method : 'post',
            body : JSON.stringify({"packageid" : pid})
        }

        try{
        await fetch(url, postData)
        .then(response=>response.json())
        .then(packArray=>{
            updatePackDetails(packArray);
        })
        }catch(error){
            alert("Network Issue..Please Try Later");
        }
    }

    const getPlace = async() => {

        let url = "https://firstenquiry.com/liveapi/Holiday/placebypackage";

        let postData = {
            headers : {'Content-Type' : 'application/json'},
            method : 'post',
            body : JSON.stringify({"packageid" : pid})
        }

        try{
        await fetch(url, postData)
        .then(response=>response.json())
        .then(placeArray=>{
            updatePlaceDetails(placeArray);
        })
        }catch(error){
            alert("Network Issue...Please Try Later");
        }
    }

    const deletePlace = async(id) => {

        updateDBtnDisable(true);

        let url = "https://firstenquiry.com/liveapi/Holiday/deleteplacefrompackage";

        let data = {"packageid" : pid, "placeid" : id};

        let postData = {
            headers : {'Content-Type' : 'application/json'},
            method : 'post',
            body : JSON.stringify(data)
        }

        try{
        await fetch(url, postData)
        .then(response=>response.text())
        .then(info=>{
            swal( " Deleted ", " Place Deleted Successfully ", "success" );
            updateDBtnDisable(false);
            getPlace();
        })
        }catch(error){
            alert("Network Error...Please Try Later");
            updateDBtnDisable(false);
        }
    }
    


    useEffect(()=>{
        getPackage();
        getPlace();
    }, []);


    return(
        <div className="container p-4">

            <div className="row bg-light bg-gradient rounded shadow p-3">

                <div className="col-lg-4">

                    <div className="row">

                        <div className="col-lg-12 fs-5 text-primary">
                            <Link className="btn btn-primary mb-4" to={`/tourpackage`}> <i className="fa-solid fa-backward"></i> Back To Tour Package </Link>
                            <p> <img src={packdetails.photo} height="250" width="100%" className="rounded" />  </p>
                            <p> Package Name : {packdetails.pname} </p>
                            <p> Number Of Days : {packdetails.days} </p>
                            <p> Cost For Each Person : {packdetails.cost} </p>
                            <p> Food Opted ? : {packdetails.food} </p>
                            <p> Mode Of Transport : {packdetails.transport} </p>
                            <p> Accomodation Opted ? : {packdetails.accomodation} </p>
                        </div>

                    </div>

                </div>

                <div className="col-lg-8">
                    <div className="row">
                        <div className="col-lg-12 mb-4">
                            <h4 className="text-center text-primary"> Number Of Places Selected : {placedetails.length} </h4>
                        </div>
                        <div className="col-lg-12">
                            {
                                placedetails.map((place, index)=>{
                                    return(
                                        <div key={index} className='row p-3 mb-5'>

                                                <div className='col-lg-4'>
                                                    <img src={place.photo} height="200px" width="100%" className="rounded shadow" />
                                                </div>


                                                <div className='col-lg-6 text-start text-primary'>

                                                    <div className='col-lg-12 fs-5'>
                                                        Name : {place.placename}
                                                    </div>

                                                    <div className='col-lg-12 mt-3 fs-5'>
                                                        About : {place.about}
                                                    </div>

                                                    <div className='col-lg-12 mt-3 fs-5'>
                                                        History : {place.history}
                                                    </div>

                                                </div>

                                                <div className='col-lg-2 d-flex align-items-center'>
                                                    <button className='btn btn-danger text-white' onClick={deletePlace.bind(this, place.placeid)} disabled={dbtndisable}>
                                                    <i className="fa-solid fa-trash"></i> Delete It </button>
                                                </div>

                                            </div>
                                    )
                                }) 
                            }
                        </div>
                    </div>
                    
                </div>

            </div>
        </div>

    )
}

export default PackageDetails;