import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

const TourPackage = () => {

    let [alldata, updateAllData] = useState([]);
    let [tourdata, updateTourData] = useState({});
    let [msg, updateMsg] = useState("");
    let [allplace, updateAllPlace] = useState([]);
    let [searchkey, updateKey] = useState("");
    let [packageid, updatePackageId] = useState("");
    let [btndisable, updateBtnDisable] = useState(false);
    let [sbtndisable, updateSBtnDisable] = useState(false);
    
    //state variables for form validation

    let[nameerror, updateNameError] = useState("");
    let[dayserror, updateDaysError] = useState("");
    let[photoerror, updatePhotoError] = useState("");
    let[costerror, updateCostError] = useState("");
    let[fooderror, updateFoodError] = useState("");
    let[transporterror, updateTransportError] = useState("");
    let[accerror, updateAccError] = useState("");
    

    
    const getPlace = async() => {
        let url = "https://firstenquiry.com/liveapi/holiday/allplace";

        try {
            await fetch(url)
                .then(response => response.json())
                .then(location => {
                    updateAllPlace(location);
                })
        } catch (error) {
            updateMsg("Network error..Please Try Later");
        }
    }


    const pickValue = (obj) => {
        tourdata[obj.target.name] = obj.target.value;
        updateTourData(tourdata);
    }


    const save = async(obj) => {
        obj.preventDefault();

        let url = "https://firstenquiry.com/liveapi/Holiday/savepackage";

        tourdata["agentid"] = localStorage.getItem("mytoken");

        let postData = {
            headers: { 'Content-Type': 'application/json' },
            method: 'post',
            body: JSON.stringify(tourdata)
        }

        let formStatus = true;

        // for package name validation
        if(tourdata.pname === undefined || tourdata.pname.trim() === "") {
            formStatus = false;
            updateNameError("Invalid Package Name");
        } else {
            updateNameError("");
        }

        // for Number Of Days Validation
        if(tourdata.days === undefined || tourdata.days === "") {
            formStatus = false;
            updateDaysError("Invalid Input");
        } else {
            updateDaysError("");
        }

        // for Photo Validation
        if(tourdata.photo === undefined || tourdata.photo.trim() === "") {
            formStatus = false;
            updatePhotoError("Invalid Input");
        } else {
            updatePhotoError("");
        }

        // for Cost Validation
        if(tourdata.cost === undefined || tourdata.cost === "0") {
            formStatus = false;
            updateCostError("Enter Correct Cost Per Person");
        } else {
            updateCostError("");
        }

        // for Food Validation
        if(tourdata.food === undefined || tourdata.food === "") {
            formStatus = false;
            updateFoodError("Invalid Input");
        } else {
            updateFoodError("");
        }

        // for Transport Validation
        if(tourdata.transport === undefined || tourdata.transport === "") {
            formStatus = false;
            updateTransportError("Invalid Input");
        } else {
            updateTransportError("");
        }

        // for Transport Validation
        if(tourdata.accomodation === undefined || tourdata.accomodation === "") {
            formStatus = false;
            updateAccError("Invalid Input");
        } else {
            updateAccError("");
        }

        if (formStatus == true) {

            updateBtnDisable(true);

            updateMsg("Please Wait...");

            try {
                await fetch(url, postData)
                    .then(response => response.text())
                    .then(info => {
                        swal(" Updated ", " Package Added Successfully ", "success");
                        updateMsg("");
                        updateBtnDisable(false);
                        obj.target.reset();
                        getData();
                    })
            } catch (error) {
                updateMsg("Network Error...Please Try Later");
                updateBtnDisable(false);
            }
        }
        
        
    }

    const getData = async() => {

        let url = "https://firstenquiry.com/liveapi/Holiday/allpackage";

        let agentid = localStorage.getItem("mytoken");

        let postData = {
            headers : {'Content-Type' : 'application/json'},
            method : 'post',
            body : JSON.stringify({"agentid" : agentid})
        }

        try{
            await fetch(url, postData)
                .then(response=>response.json())
                .then(tourArray=>{
                    updateAllData(tourArray.reverse());
                })
            }catch(error){
                updateMsg("Network Error...Please Try Later");
            }
            
        
    }

    const getPackageId = (id) => {
        updatePackageId(id);
    }

    const addPlace = async(id) => {
        
        updateSBtnDisable(true);

        let placedata = {"packageid" : packageid, "placeid" : id}

        let url = "https://firstenquiry.com/liveapi/Holiday/addplaceinpackage";

        let postData = {
            headers : {'Content-Type' : 'application/json'},
            method : 'post',
            body : JSON.stringify(placedata)
        }

        try{
        await fetch(url, postData)
        .then(response=>response.text())
        .then(info=>{
            swal( " Updated ", " Place Added Successfully ", "success" );
            updateSBtnDisable(false);
        })
        }catch(error){
            alert("Network error...Please Try Later");
            updateSBtnDisable(false);
        }

        
    }



    useEffect(() => {
        getPlace();
        getData();
    }, []);


    const formReset = () => {

        updateNameError("");
        updateDaysError("");
        updatePhotoError("");
        updateCostError("");
        updateFoodError("");
        updateTransportError("");
        updateAccError("");

    }



    return (
        <div className='container mt-5'>

            <div className='row p-5 mb-5 bg-light bg-gradient rounded shadow'>
                <div className='row mb-4'>
                    <h3 className="text-center"> <i className="fa-solid fa-bag-shopping"></i> </h3>
                    <h3 className='text-center col-lg-12'> Create Tour Package </h3>
                    <p className='text-center text-danger'> {msg} </p>

                </div>

                <form onSubmit={save}>
                    <div className='row mb-4'>

                        <div className="col-xl-3">
                            <label> Package Name </label>
                            <input type="text" className="form-control" name="pname" onChange={pickValue} />
                            <i className='text-danger'> {nameerror} </i>
                        </div>

                        <div className="col-xl-3">
                            <label> Number of Days </label>
                            <select className="form-select" name="days" onChange={pickValue}>
                                <option> Choose </option>
                                <option> 1 </option>
                                <option> 2 </option>
                                <option> 3 </option>
                                <option> 4 </option>
                                <option> 5 </option>
                                <option> 6 </option>
                                <option> 7 </option>
                                <option> 8 </option>
                                <option> 9 </option>
                                <option> 10 </option>
                                <option> 11 </option>
                                <option> 12 </option>
                                <option> 13 </option>
                                <option> 14 </option>
                                <option> 15 </option>
                            </select>
                            <i className='text-danger'> {dayserror} </i>
                        </div>

                        <div className="col-xl-3">
                            <label> Package Photo URL </label>
                            <input type="text" className="form-control" name="photo" onChange={pickValue} />
                            <i className='text-danger'> {photoerror} </i>
                        </div>

                        <div className="col-xl-3">
                            <label> Cost Per Person </label>
                            <input type="number" className="form-control" name="cost" onChange={pickValue} />
                            <i className='text-danger'> {costerror} </i>
                        </div>

                    </div>


                    <div className='row mb-4'>

                        <div className="col-xl-2">
                            <label> Food Included ? </label>
                            <select className="form-select" name="food" onChange={pickValue}>
                                <option> Choose </option>
                                <option> Yes </option>
                                <option> No </option>
                            </select>
                            <i className='text-danger'> {fooderror} </i>
                        </div>

                        <div className="col-xl-2">
                            <label> Mode Of Transport </label>
                            <select className="form-select" name="transport" onChange={pickValue}>
                                <option> Choose </option>
                                <option> Car </option>
                                <option> Mini Bus </option>
                                <option> Flight </option>
                                <option> Train </option>
                            </select>
                            <i className='text-danger'> {transporterror} </i>
                        </div>

                        <div className="col-xl-3">
                            <label> Accomodation Included ? </label>
                            <select className="form-select" name="accomodation" onChange={pickValue}>
                                <option> Choose </option>
                                <option> Yes </option>
                                <option> No </option>
                            </select>
                            <i className='text-danger'> {accerror} </i>
                        </div>



                        <div className='col-lg-5 text-center pt-2'>
                            <button type="submit" className='btn btn-success m-3' disabled={btndisable}> Submit </button>
                            <button type="reset" className='btn btn-warning' onClick={formReset}> Reset </button>
                        </div>


                    </div>
                </form>
            </div>

            
            <div className='row mt-5 mb-5'>
                <div className='col-xl-12 text-center'>
                    <h3> Number Of Packages : {alldata.length} </h3>
                </div>
            </div>


            {
                alldata.map((pack, index) => {
                    return (
                        <div key={index} className='row mb-5 bg-light bg-gradient shadow rounded p-3'>

                            <div className='col-lg-10'>

                                <div className='row'>

                                    <div className='col-xl-3'>
                                        <img src={pack.photo} height="200" width="100%" className="rounded" />
                                    </div>

                                    <div className='col-xl-7 fs-6'>
                                        <p> Package Name :  <Link className='text-primary text-decoration-none' to={`/pdetails/${pack.pid}`}> {pack.pname} </Link> </p>
                                        <p> Number Of Days : {pack.days} </p>
                                        <p> Cost Per Person : {pack.cost} </p>
                                        <p> Food Included? : {pack.food} </p>
                                        <p> Mode Of Transport : {pack.transport} </p>
                                        <p> Accomodation Included? : {pack.accomodation} </p>
                                    </div>

                                    <div className='col-xl-2 fw-bold'>
                                        <p> Active : {pack.active} </p>
                                    </div>

                                </div>

                            </div>

                            <div className='col-lg-2 text-end d-flex align-items-center'>
                                <button type="button" className="btn btn-success" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={getPackageId.bind(this, pack.pid)}>
                                <i className="fa-solid fa-plus"></i> Add Place
                                </button>
                            </div>

                        </div>
                    )
                })
            }











            {
            // Modal Code Starts Here
            }


            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">

                <div className="modal-dialog modal-lg">

                    <div className="modal-content">

                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel"> Place Details </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body text-primary">
                            <div className='row mb-4'>
                                <div className='col-lg-12'>
                                    <input type="text" className='form-control' placeholder='Search' onChange={obj => updateKey(obj.target.value)} />
                                </div>
                            </div>

                            {
                                allplace.map((place, index) => {
                                    searchkey = searchkey.toLowerCase();
                                    if (place.placename.toLowerCase().match(searchkey) || place.about.toLowerCase().match(searchkey))
                                    return (
                                            
                                            <div key={index} className='row mb-5 shadow rounded p-3'>

                                                <div className='col-lg-4'>
                                                    <img src={place.photo} height="200px" width="100%" className="rounded shadow" />
                                                </div>


                                                <div className='col-lg-6 text-start'>

                                                    <div className='col-lg-12 fs-5'>
                                                        Name : {place.placename}
                                                    </div>

                                                    <div className='col-lg-12 mt-3 fs-5'>
                                                        About : {place.about}
                                                    </div>

                                                    <div className='col-lg-12 mt-3 fs-5'>
                                                        History : {place.history}
                                                    </div>

                                                </div>

                                                <div className='col-lg-2 d-flex align-items-center'>
                                                    <button className='btn btn-success text-white' onClick={addPlace.bind(this, place.placeid)} disabled={sbtndisable}>
                                                    <i className="fa-solid fa-plus"></i> Select It </button>
                                                </div>

                                            </div>
                                        )
                                })
                            }
                        </div>

                        <div className="modal-footer justify-content-center">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>

                    </div>

                </div>

            </div>

            {
                // Modal Code Ends Here 
            }

        </div>
    )
}



export default TourPackage;