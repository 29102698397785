import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Profile = () => {

    let[agent, updateAgent] = useState({});
    let[msg, updateMsg] = useState("");
    
    
    const getAgent = async() => {

        let url = "https://firstenquiry.com/liveapi/Holiday/agentdetails";

        let agentid = localStorage.getItem("mytoken");

        let postData = {
            headers : {'Content-Type' : 'application/json'},
            method : 'post',
            body : JSON.stringify({"agentid" : agentid})
        }

        try{
            await fetch(url, postData)
            .then(response=>response.json())
            .then(info=>{
                updateAgent(info);
            })
            }catch(error){
                updateMsg("Network error...Please Try Later");
            }
        

    }

    useEffect(()=>{
        getAgent();
    });

    return(
        <div className="container mt-5">

            <div className="row bg-light bg-gradient rounded shadow p-5">

                <h3 className="text-center text-primary"> <i className="fa-solid fa-user"></i> </h3>
                <h3 className="text-center text-primary mb-1"> Agent Profile </h3>
                <i className="text-danger mb-4"> {msg} </i>

                <div className="col-xl-5 fs-5">
                    <p> Name : {agent.fullname} </p>
                    <p> Email : {agent.email} </p>
                    <p> Password : {agent.password} </p>
                    <p> Mobile : {agent.mobile} </p>
                    <p> Address : {agent.address} </p>
                    <p> City : {agent.city} </p>
                    <p> Pin Code : {agent.pincode} </p>
                </div>

                <div className="col-xl-5 fs-5">
                    <p> Website : {agent.website} </p>
                    <p> GST Number : {agent.gst} </p>
                    <p> PAN Number : {agent.pan} </p>
                    <p> TAN Number : {agent.tan} </p>
                </div>

                <div className="col-xl-2 d-flex align-items-center">
                    <Link className="btn btn-lg btn-warning" to="/editprofile">
                    <i className="fa-solid fa-pen-to-square"></i> Edit Profile </Link>
                </div>

            </div>

        </div>
    )
}

export default Profile;