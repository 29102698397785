import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";

const AgentList = () => {

    let[alldata, updateData] = useState([]);
    let[msg, updateMsg] = useState("");
    let[delbtndisable, updateDelBtnDisable] = useState(false);

    const getAgent = async() => {

        let url = "https://firstenquiry.com/liveapi/Holiday/allagent";

        let agentid = localStorage.getItem("mytoken");

        let postData = {
            headers : {'Content-Type' : 'application/json'},
            method : 'post',
            body : JSON.stringify({"agentid" : agentid})
        }

        try{
        await fetch(url, postData)
        .then(response=>response.json())
        .then(agentArray=>{
            updateData(agentArray);
        })
        }catch(error)
        {
            updateMsg("Network error...Please Try Later...");
        }
    }


    const deleteAgent = async(agentid) =>{

        updateDelBtnDisable(true);

        let url = "https://firstenquiry.com/liveapi/Holiday/deleteagent";

        let id = localStorage.getItem("mytoken");

        let postData = {
            headers : {'Content-Type' : 'application/json'},
            method : 'post',
            body : JSON.stringify({"id" : id, "agentid" : agentid})
        }

        try{
        await fetch(url, postData)
        .then(response=>response.text())
        .then(res=>{
            swal("Deleted", "Agent Deleted Successfully", "success");
            updateDelBtnDisable(false);
            getAgent();
        })
        }catch(error){
            updateMsg("Network error...Please Try Later...");
            updateDelBtnDisable(false);
        }

    }

    useEffect(()=>{
        getAgent();
    },[]);


    return(

        <div className="container mt-5">

            <div className="row mt-5">

                <div className="col-xl-12 text-center">

                    <h3 className="text-center"> <i className="fa-solid fa-users"></i> </h3>
                    <h3 className="text-center mb-1"> Total Number Of Agents : {alldata.length} </h3>

                    <i className="text-danger text-center"> {msg} </i>

                    <table className="table table-bordered border-info mt-4">

                        <thead className="align-top">
                            <tr>
                                <th> Agent Id </th>
                                <th> Name </th>
                                <th> Email </th>
                                <th> Password </th>
                                <th> Mobile </th>
                                <th> Address </th>
                                <th> City </th>
                                <th> Pincode </th>
                                <th> Website </th>
                                <th> GST </th>
                                <th> PAN </th>
                                <th> TAN </th>
                                <th> Created On </th>
                                <th> Action </th>
                            </tr>
                        </thead>

                        <tbody>

                            {

                                alldata.map((agent, index)=>{

                                    return(

                                        <tr key={index}>
                                            <td> {agent.userid} </td>
                                            <td> {agent.fullname} </td>
                                            <td> {agent.email} </td>
                                            <td> {agent.password} </td>
                                            <td> {agent.mobile} </td>
                                            <td> {agent.address} </td>
                                            <td> {agent.city} </td>
                                            <td> {agent.pincode} </td>
                                            <td> {agent.website} </td>
                                            <td> {agent.gst} </td>
                                            <td> {agent.pan} </td>
                                            <td> {agent.tan} </td>
                                            <td> {agent.createdon} </td>
                                            <td> 
                                                
                                                <Link className="btn btn-warning" to={`/editagent/${agent.userid}`}> 
                                                 <i className="fa-solid fa-pen-to-square me-1"></i>Edit 
                                                </Link>

                                                <button className="btn btn-danger mt-2" disabled={delbtndisable} onClick={deleteAgent.bind(this, agent.userid)}>
                                                <i className="fa-solid fa-trash"></i> Delete 
                                                </button>

                                            </td>
                                        </tr>        

                                    )

                                })

                            }
                        </tbody>


                    </table>

                </div>

            </div>
            
        </div>
    )


}

export default AgentList;