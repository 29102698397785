import { HashRouter, Routes, Route, Link } from "react-router-dom";
import Location from "../admin/location";
import TourPlaces from "../admin/tourplaces";
import TourPackage from "../admin/tourpackage";
import PackageDetails from "../admin/packagedetails";
import Profile from "./profile";
import logout from "../home/logout";
import EditProfile from "./editprofile";


const AgentApp = () => {

    return(
        <div className="fullpage">
            <HashRouter>
                <nav className="navbar navbar-expand-sm sticky-top navbar-dark bg-primary">
                    <div className="container">
                        <a className="navbar-brand" href="/"> <i className="fa fa-plane"></i> Holiday Packages (AGENT) </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="mynavbar">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">

                                <li className="nav-item me-3">
                                    <Link className="nav-link active" to="/" > <i className="fa-solid fa-location-dot"></i> Location </Link>
                                </li>

                                <li className="nav-item me-3">
                                    <Link className="nav-link active" to="/tourplace" > <i className="fa-solid fa-umbrella-beach"></i> Tour Places </Link>
                                </li>

                                <li className="nav-item me-3">
                                    <Link className="nav-link active" to="/tourpackage" > <i className="fa-solid fa-bag-shopping"></i> Tour Packages </Link>
                                </li>

                                <li className="nav-item me-3">
                                    <Link className="nav-link active" to="/profile" > <i className="fa fa-user"></i> Profile </Link>
                                </li>

                                <li className="nav-item">
                                    <Link className="text-warning text-decoration-none nav-link" onClick={logout}>
                                        <i className="fa-solid text-warning fa-right-from-bracket me-1"></i>
                                        {localStorage.getItem("myname")} - Logout
                                    </Link>
                                </li>

                            </ul>

                        </div>
                    </div>
                </nav>

                


                <Routes>
                    <Route exact path="/" element={<Location />} />
                    <Route exact path="/tourplace" element={<TourPlaces />} />
                    <Route exact path="/tourpackage" element={<TourPackage />} />
                    <Route exact path="/pdetails/:pid" element={<PackageDetails />} />
                    <Route exact path="/profile" element={<Profile />} />
                    <Route exact path="/editprofile" element={<EditProfile />} />
                </Routes>

            </HashRouter>
        </div>
    )
    
}


export default AgentApp;






