import { useState } from "react";
import swal from "sweetalert";


const AddAgent = () => {

    let[agent, updateAgent] = useState({});
    let[msg, updateMsg] = useState("");
    let[btndisable, updateBtnDisable] = useState(false);


    //state variables for form validation

    let[nameerror, updateNameError] = useState("");
    let[emailerror, updateEmailError] = useState("");
    let[mobileerror, updateMobileError] = useState("");
    let[passworderror, updatePasswordError] = useState("");
    let[addresserror, updateAddressError] = useState("");

    const pickValue = (obj) => {
        agent[obj.target.name] = obj.target.value;
        updateAgent(agent);
    }


    const save = async(obj) => {

        obj.preventDefault();

        let url = "https://firstenquiry.com/liveapi/Holiday/saveagent";

        let postData = {
            headers : {'Content-Type' : 'application/json'},
            method : 'post',
            body : JSON.stringify(agent)
        }

        let formStatus = true;

        // for name validation
        if(agent.name === undefined || agent.name.trim() === "") {
            formStatus = false;
            updateNameError("Invalid Name");
        } else {
            updateNameError("");
        }

        // for email validation
        let epattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        if(!epattern.test(agent.email)){
            formStatus = false;
            updateEmailError(" Invalid Email Address ");
        }else{
            updateEmailError("");
        }

        // for password validation
        let ppattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

        if(!ppattern.test(agent.password)){
            formStatus = false;
            updatePasswordError("Minimum Eight Characters, atleast one letter, one number, one special character");
        }else{
            updatePasswordError("");
        }

        // for mobile validation
        let mpattern = /^[0]?[6789]\d{9}$/;

        if(!mpattern.test(agent.mobile)){
            formStatus = false;
            updateMobileError("Invalid Mobile Number");
        }else{
            updateMobileError("");
        }

        // for address validation
        if(agent.address===undefined || agent.address.trim()===""){
            formStatus = false;
            updateAddressError("Invalid Address");
        }else{
            updateAddressError("");
        }

        if(formStatus==true){

        updateBtnDisable(true);
        try{
        await fetch(url, postData)
        .then(response=>response.text())
        .then(res=>{
            swal("Updated", "Agent Added Successfully", "success");
            updateBtnDisable(false);
            obj.target.reset();
        })
        }catch(error){
            updateMsg("Network Error...Please Try Later...")
            updateBtnDisable(false);
        }

        }

    }


    const formReset = () => {

        updateNameError("");
        updateEmailError("");
        updatePasswordError("");
        updateMobileError("");
        updateAddressError("");

    }


    
    return(

        <div className="container mt-5">

            <form onSubmit={save} className="rounded shadow bg-light bg-gradient p-4">

                <div className="row mb-4">

                    <h3 className="text-center"> <i className="fa-solid fa-user"></i> </h3>
                    <h3 className="text-center mb-1"> Add Agent Details Below </h3>

                    <i className="text-center text-danger mb-4"> {msg} </i>

                    <div className="col-xl-4">
                        <label> Business Name <i className="text-danger">*</i> </label>
                        <input type="text" className="form-control" name="name" onChange={pickValue} />
                        <i className="text-danger"> {nameerror} </i>
                    </div>

                    <div className="col-xl-4">
                        <label> Email <i className="text-danger">*</i> </label>
                        <input type="email" className="form-control" name="email" onChange={pickValue} />
                        <i className="text-danger"> {emailerror} </i>
                    </div>

                    <div className="col-xl-4">
                        <label> Password <i className="text-danger">*</i> </label>
                        <input type="password" className="form-control" name="password" onChange={pickValue} />
                        <i className="text-danger"> {passworderror} </i>
                    </div>

                </div>


                <div className="row mb-4">

                    <div className="col-xl-4">
                        <label> Mobile Number <i className="text-danger">*</i> </label>
                        <input type="number" className="form-control" name="mobile" onChange={pickValue} />
                        <i className="text-danger"> {mobileerror} </i>
                    </div>

                    <div className="col-xl-8">
                        <label> Address <i className="text-danger">*</i> </label>
                        <textarea className="form-control" name="address" onChange={pickValue}/>
                        <i className="text-danger"> {addresserror} </i>
                    </div>

                </div>


                <div className="row mb-4">

                    <div className="col-xl-4">
                        <label> City </label>
                        <input type="text" className="form-control" name="city" onChange={pickValue} />
                    </div>

                    <div className="col-xl-4">
                        <label> Pin Code </label>
                        <input type="number" className="form-control" name="pincode" onChange={pickValue} />
                    </div>

                    <div className="col-xl-4">
                        <label> Website </label>
                        <input type="text" className="form-control" name="website" onChange={pickValue} />
                    </div>
                    
                </div>

                <div className="row mb-5">

                    <div className="col-xl-4">
                        <label> GST Number </label>
                        <input type="text" className="form-control" name="gst" onChange={pickValue} />
                    </div>

                    <div className="col-xl-4">
                        <label> PAN Number </label>
                        <input type="text" className="form-control" name="pan" onChange={pickValue} />
                    </div>

                    <div className="col-xl-4">
                        <label> TAN Number </label>
                        <input type="text" className="form-control" name="tan" onChange={pickValue} />
                    </div>

                </div>

                <div className="row">
                    <div className="col-xl-12 text-center">
                        <button type="submit" className="btn btn-success me-3" disabled={btndisable}> Submit </button>
                        <button type="reset" className="btn btn-warning" onClick={formReset}> Reset </button>
                    </div>
                </div>

            </form>

        </div>
    )

}

export default AddAgent;