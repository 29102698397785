
import { useState, useEffect } from "react";
import swal from "sweetalert";

const TourPlaces = () =>{
    let[msg, updateMsg] = useState("");
    let[alllocation, updateAllLocation] = useState([]);
    let [searchkey,updateKey] = useState("");
    
    let [btndisable, updateBtnDisable] = useState(false);

    //state variables for form validation
    let[cityerror, updateCityError] = useState("");
    let[placeerror, updatePlaceError] = useState("");
    let[photoerror, updatePhotoError] = useState("");
    let[temperror, updateTempError] = useState("");
    let[abouterror, updateAboutError] = useState("");
    let[historyerror, updateHistoryError] = useState("");

    

    const getLocation = async() => {
        let url = "https://firstenquiry.com/liveapi/holiday/alllocation";

        try{
        await fetch(url)
        .then(response=>response.json())
        .then(location=>{
            updateAllLocation(location);
        })
        }catch(error){
            updateMsg("Network Error.. Please Try After Sometime");
        }
        
    }

    useEffect(()=>{
        getLocation();
        getPlace();
    }, []);



    // save the value

    let[placeData, processData] = useState( {} );

    const pickValue = (obj) =>{
        placeData[obj.target.name] = obj.target.value;
        processData( placeData );
    }

    
    const save = async(obj) =>{

        obj.preventDefault();

        let url = "https://firstenquiry.com/liveapi/holiday/saveplace"; // post / { email: "admin1@gmail.com", password: "454545" }
        let postData = {
            headers:{'Content-Type':'application/json'},
            method:'post',
            body:JSON.stringify(placeData)
        }

        let formStatus = true;

        // for city validation
        if(placeData.cityid === undefined || placeData.cityid === "") {
            formStatus = false;
            updateCityError("Invalid City");
        } else {
            updateCityError("");
        }


        // for place validation
        if(placeData.placename === undefined || placeData.placename.trim() === "") {
            formStatus = false;
            updatePlaceError("Invalid Place");
        } else {
            updatePlaceError("");
        }

        // for photo validation
        if(placeData.photo === undefined || placeData.photo.trim() === "") {
            formStatus = false;
            updatePhotoError("Invalid Photo");
        } else {
            updatePhotoError("");
        }

        // for temp validation
        if(placeData.temp === undefined || placeData.temp.trim() === "") {
            formStatus = false;
            updateTempError("Invalid Temperature");
        } else {
            updateTempError("");
        }

        // for about validation
        if(placeData.about === undefined || placeData.about.trim() === "") {
            formStatus = false;
            updateAboutError("Invalid Input");
        } else {
            updateAboutError("");
        }

        // for history validation
        if(placeData.history === undefined || placeData.history.trim() === "") {
            formStatus = false;
            updateHistoryError("Invalid Input");
        } else {
            updateHistoryError("");
        }

        if (formStatus == true) {
            updateMsg("Please Wait...");

            updateBtnDisable(true);

            try{
            await fetch(url, postData)
                .then(response => response.text())
                .then(info => {
                    swal(" Updated ", " Place Added Successfully ", "success");
                    updateMsg("");
                    updateBtnDisable(false);
                    obj.target.reset();
                    getPlace();
                })
            }catch(error){
                updateMsg("Network error...Please Try Later...");
                updateBtnDisable(false);
            }
        }
    }

    let [allplace, updatePlace] = useState([]);

    const getPlace = async() =>{
        let url = "https://firstenquiry.com/liveapi/holiday/allplace";

        try{
        await fetch(url)
        .then(response=>response.json())
        .then(location=>{
            updatePlace(location);
        })
        }catch(error){
            updateMsg("Network error..Please Try Later");
        }
    }

 

    const SearchCity = async(cityid) =>{

        let url = "https://firstenquiry.com/liveapi/holiday/searchplace";

        let postData = {
            headers:{'Content-Type':'application/json'},
            method:'post',
            body:JSON.stringify({"cityid":cityid})
        }

        try{
        await fetch(url, postData)
        .then(response=>response.json())
        .then(location=>{
            updatePlace(location);
        })
        }catch(error){
            updateMsg("Network error..Please Try Later");
        }

    }

    const formReset = () => {

        updateCityError("");
        updatePlaceError("");
        updatePhotoError("");
        updateTempError("");
        updateAboutError("");
        updateHistoryError("");

    }

    

    return(
        <div className='container mt-5'>
            
            <form onSubmit={save} className="bg-light bg-gradient rounded shadow p-5">
                <div className='row'>
                    <h3 className="text-center"> <i className="fa-solid fa-umbrella-beach"></i> </h3>
                    <h3 className='text-center col-lg-12 mb-4'> Enter New Tour Place </h3>
                    <p className="text-danger text-center"> {msg} </p>
                    <div className="col-xl-3 mb-3">
                        <label> City Name </label>
                        <select className="form-select" name="cityid" onChange={pickValue}>
                            <option value=""> Choose </option>
                            {
                                alllocation.map((location, index)=>{
                                    return(
                                        <option key={index} value={location.id}> {location.cityname} </option>
                                    )
                                })
                            }
                            
                        </select>
                        <i className="text-danger">{cityerror}</i>
                    </div>
                    <div className="col-xl-3 mb-3">
                        <label> Place Name </label>
                        <input type="text" className="form-control" name="placename" onChange={pickValue}/>
                        <i className="text-danger">{placeerror}</i>
                    </div>
                    <div className="col-xl-3 mb-3">
                        <label> Place Photo </label>
                        <input type="text" className="form-control" name="photo" onChange={pickValue}/>
                        <i className="text-danger">{photoerror}</i>
                    </div>
                    <div className="col-xl-3 mb-3">
                        <label> Min to Max Temperature  </label>
                        <input type="text" className="form-control" name="temp" onChange={pickValue}/>
                        <i className="text-danger">{temperror}</i>
                    </div>
                    <div className="col-xl-4 mb-3">
                        <label> About This Place  </label>
                        <textarea className="form-control" name="about" onChange={pickValue}></textarea>
                        <i className="text-danger">{abouterror}</i>
                    </div>
                    <div className="col-xl-4 mb-3">
                        <label> History Of Place  </label>
                        <textarea className="form-control" name="history" onChange={pickValue}></textarea>
                        <i className="text-danger">{historyerror}</i>
                    </div>
                    <div className="col-xl-4 mb-3 pt-3 text-center">
                        <br/>
                        <button className="btn btn-success me-3" type="submit" disabled={btndisable}> Submit </button>
                        <button className="btn btn-warning" type="reset" onClick={formReset}> Reset </button>
                    </div>
                </div>
            </form>

            <div className="row mt-5 mb-5">

                <h3 className='text-center col-lg-12 mb-4'> Available Tour Places : {allplace.length} </h3>

                <div className="col-lg-4">
                    <select className="form-select" onChange={obj=>SearchCity(obj.target.value)}>
                        <option value=""> Search By City </option>
                        {
                                alllocation.map((location, index)=>{
                                    return(
                                        <option key={index} value={location.id}> {location.cityname} </option>
                                    )
                                })
                        }
                    </select>
                </div>

                <div className="col-lg-4"></div>

                <div className="col-lg-4">
                    <input type="text" className="form-control" placeholder="Search By Place" onChange={obj=>updateKey(obj.target.value)}/>
                </div>
            </div>

            {
                allplace.map((place, index)=>{
                    searchkey = searchkey.toLowerCase(); 
                    if(place.placename.toLowerCase().match(searchkey) || place.about.toLowerCase().match(searchkey))
                    return(
                        <div className="row mb-4 rounded shadow p-4" key={index}>
                            <div className="col-lg-3"> <h3> {place.placename} </h3> </div>
                            <div className="col-lg-3"> <img src={place.photo} height="150" width="100%" className="rounded"/> </div>
                            <div className="col-lg-3"> {place.about} </div>
                            <div className="col-lg-3"> {place.history} </div>
                        </div>
                    )
                })
            }

        </div>
    )
}

export default TourPlaces;