
const logout = () => {

    localStorage.clear();
    window.location.href="http://localhost:3000/";
    window.location.reload();
    
    }

export default logout;

